import React, { Suspense, lazy } from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./App.css"
import 'react-toastify/dist/ReactToastify.css';


import Loader from "./components/Loader/Loader";
const App = lazy(() => import("./App"));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
    <StyleProvider hashPriority="high">
        <App/>
    </StyleProvider>
    </Suspense>
  </React.StrictMode>
);
reportWebVitals();
